import { isDefined } from "@clipboard-health/util-ts";
import { Alert, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  Channel,
  type RenderCustomMessage,
  sendBirdSelectors,
  useSendbirdStateContext,
} from "sendbird-uikit";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { useGetChatChannels } from "../Facilities/Chat/api/useGetChatChannels";
import { AppBarHeader, BackButtonLink, PageWithHeader } from "../lib";
import { transformPlacementData, useGetPlacement } from "../Placements/api/useGetPlacement";
import { getShiftDiscoveryTheme } from "../ShiftDiscovery/theming/theme";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import { ChatSkeleton } from "./components/ChatSkeleton";
import { PlacementChatHeader } from "./components/PlacementChatHeader";
import { PlacementChatMessage } from "./components/PlacementChatMessage";

export function PlacementChatPage() {
  const { placementId, workplaceId } = useParams<{
    placementId: string;
    workplaceId: string;
  }>();
  const worker = useDefinedWorker();

  const {
    data: placement,
    isLoading: isPlacementLoading,
    isSuccess: isPlacementSuccess,
  } = useGetPlacement(
    { placementId, workerId: worker.userId },
    { enabled: isDefined(placementId) && isDefined(worker.userId) }
  );
  const placementDetails = isPlacementSuccess ? transformPlacementData(placement) : undefined;

  const sendBirdState = useSendbirdStateContext();
  const sdk = sendBirdSelectors.getSdk(sendBirdState);
  const sendBirdGroupChannelSdk = sdk?.GroupChannel;
  const {
    isLoading: isLoadingChatChannels,
    data: chatChannelsData,
    error: chatChannelsError,
  } = useGetChatChannels({
    enabled: isDefined(sendBirdGroupChannelSdk) && isDefined(placementId),
  });
  const currentChannel = chatChannelsData?.channels?.find(
    (channel) => channel.url === `${workplaceId}_${worker.userId}_${placementId}`
  );

  const showChatLoader =
    (isLoadingChatChannels || isPlacementLoading) && !isDefined(sendBirdGroupChannelSdk);
  const showChatError = isDefined(sendBirdGroupChannelSdk) && chatChannelsError;
  const showChatNotFound =
    isDefined(sendBirdGroupChannelSdk) && !isLoadingChatChannels && !isDefined(currentChannel);

  return (
    <PageWithHeader
      appBarHeader={
        <AppBarHeader
          title="Chat"
          leftCta={<BackButtonLink defaultBackTo={DeprecatedGlobalAppV1Paths.OPEN_SHIFTS} />}
        />
      }
    >
      <ThemeProvider theme={getShiftDiscoveryTheme()}>
        {showChatLoader && <ChatSkeleton />}
        {showChatError && (
          <Alert severity="error">
            {chatChannelsError.message ?? "Error loading chat channels"}
          </Alert>
        )}
        {showChatNotFound && <Alert severity="error">Could not find chat channel</Alert>}
        {isDefined(currentChannel) && (
          <div className="chat-container">
            <Channel
              channelUrl={currentChannel.url}
              renderChatHeader={() => (
                <PlacementChatHeader channel={currentChannel} placement={placementDetails} />
              )}
              renderCustomMessage={PlacementChatMessage as unknown as RenderCustomMessage}
            />
          </div>
        )}
      </ThemeProvider>
    </PageWithHeader>
  );
}
