import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";

export function useIsPlacementChatsEnabled() {
  return useCbhFlag(CbhFeatureFlag.ROLLOUT_PLACEMENT_CHAT, {
    defaultValue: {
      autoCreateChatOnApplication: false,
      enableHcpChatWithoutApplication: false,
    },
  });
}
