import { Box, Skeleton, Stack } from "@mui/material";

export function ChatSkeleton() {
  return (
    <Stack
      spacing={4}
      padding={2}
      direction="column"
      sx={{ height: "80%" }}
      justifyContent="center"
    >
      {Array.from({ length: 2 }).map((_, index) => (
        <Box
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          display="flex"
          alignItems="flex-end"
          gap={1}
          flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
        >
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton
            variant="rounded"
            width="60%"
            height="32px"
            sx={{
              borderRadius: "20px",
              marginLeft: index % 2 === 0 ? 0 : "auto",
              marginRight: index % 2 === 0 ? "auto" : 0,
            }}
          />
        </Box>
      ))}
    </Stack>
  );
}
