import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { useCreateOrGetPlacementCandidate } from "../../PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { type PlacementWithDetails } from "../../Placements/api/useFetchPaginatedPlacements";
import { getPlacementDetailsPath } from "../../Placements/paths";
import { Button } from "../../ShiftDiscovery/components/Button";
import { useDefinedWorker } from "../../Worker/useDefinedWorker";
import { type PlacementChatMetadata } from "../types";

interface PlacementChatHeaderProps {
  channel: SendBird.GroupChannel;
  placement?: PlacementWithDetails;
}

export function PlacementChatHeader(props: PlacementChatHeaderProps) {
  const { channel, placement } = props;
  const worker = useDefinedWorker();
  const { data: placementCandidate, isLoading: isPlacementCandidateLoading } =
    useCreateOrGetPlacementCandidate(
      { workerId: worker.userId },
      { enabled: isDefined(worker.userId) }
    );
  const placementChatMetadata = channel.getCachedMetaData() as PlacementChatMetadata;

  const hasApplied = isDefined(placement) && (placement.applications?.length ?? 0) > 0;

  return (
    <div className="sendbird-custom-chat-header">
      <Stack spacing={5}>
        <Stack spacing={1}>
          <Text variant="h5">{placementChatMetadata?.facilityName}</Text>
          <Text variant="body1" color="text.secondary">
            Job Listing: {placementChatMetadata?.placementTitle}
          </Text>
        </Stack>
        {isDefined(placement) && isDefined(placementCandidate) && (
          <Stack direction="row" spacing={3}>
            {!hasApplied && (
              <Button
                variant="contained"
                size="small"
                disabled={isPlacementCandidateLoading}
                href={getPlacementDetailsPath(placement.id, placementCandidate.id)}
              >
                Apply to job
              </Button>
            )}
            <Button
              variant="outlined"
              size="small"
              disabled={isPlacementCandidateLoading}
              href={getPlacementDetailsPath(placement.id, placementCandidate.id)}
            >
              View listing
            </Button>
          </Stack>
        )}
      </Stack>
    </div>
  );
}
